import "./Logo.css";

const Logo = () => {
    return (
        <div>
            <p className="logo">
                Infinita<span>.</span>
            </p>
        </div>
    );
};

export default Logo;
